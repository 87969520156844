import "babel-polyfill";

// Styles:
import "../scss/style.scss";

// Highcharts:
import "./vendor";
import "./highcharts";
import "./leaflet";
import "./render-queue";

// open.DASH:
import instance from "opendash";

// Plugins:
import routingPlugin from "opendash/plugins/router/qs";
import eudPlugin from "opendash-plugin-eud";
import owPlugin from "opendash-plugin-openware";

// Config
import parseConfig from "./parse.config";
import openwareConfig from "./openware.config";

// Translations:
import deTranslation from "opendash/translations/de";
import enTranslation from "opendash/translations/en";

// User Adapter:
import userAdapter from "@opendash/user-adapter-parse-plus";

// Components:
import heroComponent from "./components/hero.component";

// Widgets
const widgets = [
  require("opendash-widget-highcharts-live").default,
  require("opendash-widget-iot-kpi").default,
  require("opendash-widget-highcharts-minmaxline").default,
  require("opendash-widget-highcharts-heatmap").default,
  require("opendash-widget-highcharts-histogramm").default,
  require("opendash-widget-highcharts-gauge").default,
  require("opendash-widget-highcharts-polar").default,
  require("opendash-widget-highcharts-columnrange").default,
  require("opendash-widget-highcharts-timepattern").default,
  require("opendash-widget-iot-states").default,
  require("opendash-widget-iot-topstates").default,
  require("opendash-widget-iot-statesgantt").default,
  require("opendash-widget-energy-peak-table").default,
  require("opendash-widget-highcharts-linecompare").default,
  require("opendash-widget-table").default
];

// Core Config:
instance.env("OD-EVENTS-LOG", false);
instance.env("OD-DASHBOARDS-BY-LOCATION", true);
instance.env("OD-DATA-VALIDATION", false);

// Other Config:
instance.env("OD-ANALYTICS-SENSORS-BASEURL", "api/analytics");
instance.env("OD-ANALYTICS-OPERATIONS-BASEURL", "api/analytics");
instance.env("OD-ANALYTICS-ALARMS-BASEURL", "api/alarms");
instance.env("OD-ANALYTICS-APPID", "01234567890");

// Register Plugins
instance.use(routingPlugin);
instance.use(eudPlugin);
instance.use(owPlugin, openwareConfig);

// Translations:
instance.registerTranslation(deTranslation);
instance.registerTranslation(enTranslation);

// User Adapter
instance.registerUserAdapter(userAdapter, parseConfig);

// Register Widgets
instance.registerWidgets(widgets);

// Start instance
instance.start();

// App initialisieren:
const app = angular.module("app", [instance.name]);

app.component("hero", heroComponent);

app.run([
  "$injector",
  $injector => {
    const $header = $injector.get("opendash/services/header");
    const $event = $injector.get("opendash/services/event");

    $header
      .addLogo(
      //("https://www.si-automation.de/images/logo.png");
      //"http://media.berufsmesse-olpe.de/tnusr1/263-Dornseifers_FrischeMarkt_Logo_CMYK_ECIv2_150_50hoch_Design_Verlauf_Digital_SCHRAEG.jpg"
      //"https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/EJOT-logo.svg/2000px-EJOT-logo.svg.png"
      //"https://www.iptro.de/fileadmin/Resources/Public/Images/Header/logo-iptro.svg"
      require("/public/images/logo2.png")
      //"https://www.digital-energy.nrw/wp-content/uploads/2019/06/cropped-Unbenannt-6.png"
      //"https://www.slawinski.de/cms/upload/layout/slawinski-logo.png"
      );

    $event.on("od-dashboard-ready", () => {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    });
  }
]);
